import React from "react";
import Booking from "../Booking";
import * as S from "./GuestBooking.styles";
import { GuestDataProvider } from "../../../hooks/useGuestDataContext";

type Props = {};

const GuestBooking = ({}: Props) => {
	return (
		<GuestDataProvider>
			<S.Container>
				<Booking onNewAppointmentClick={() => window.location.reload()} />
			</S.Container>
		</GuestDataProvider>
	);
};

export default GuestBooking;
