import React from "react";
import GuestBooking from "../../components/Booking/GuestBooking/GuestBooking";
import MainLayout from "../../components/MainLayout/MainLayout";

const booking = () => {
	return (
		<MainLayout lang="en" title="booking">
			<GuestBooking />
		</MainLayout>
	);
};

export default booking;
